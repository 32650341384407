module.exports = require("deepmerge")(require("./config.default.js"), {
  version: {
    codepushDeploymentVersion: {
      iOS: "",
      android: ""
    }
  },
  native: {
    name: {
      default: "MOM_D"
    },
    id: "com.mobilespot.mom.dev",
    // iOS
    appleTeamId: "2BAP3P29V2",
    // iosAppStoreUrl: 'https://itunes.apple.com/app/id1484463502',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    playStoreUrl: ""
  },
  web: {
    url: "mom-dev.mobile-spot.com"
  }
});