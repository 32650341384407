import {
  CONFIG_JSON_LOADED,
  POLL_CONFIG_LOADED,
  DATA_ASSETS_UPDATED,
  HAS_NAVIGATED,
  LANG_CHANGED,
  GOOGLE_MAP_IS_RESTARTING,
  GOOGLE_MAP_LOADED,
  GOOGLE_MAP_RELOAD,
  PROFILE_CHANGED,
  //TOGGLE_LOCATION_STATUS,
  //TOGGLE_LOCATION_CAPABILITY_STATUS,
  //TOGGLE_PMR_STATUS,
  TOGGLE_MENU,
  UPDATE_PAGE_STATE,
  WINDOW_RESIZED,
  FETCH_GMAP_PLACES,
  GOOGLE_MAP_RESET,
} from 'src/store/actionTypes';

import {
  configJsonLoaded,
  togglePageAfterNavigation,
  pollConfigLoaded,
  getPageCommonDefaultState,
  langChanged,
  profileChanged,
  //toggleLocationStatus,
  //toggleLocationCapabilityStatus,
  //togglePMRStatus,
  toggleMenu,
  updateObject,
  updatePageState,
  windowResized,
} from 'src/store/reducers/commons';

import { GOOGLE_MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import STATUS from 'src/store/fetchStatuses';

import googleMapContext from './googleMapContext'; // to do to rename

const getDefaultState = () => ({
  ...getPageCommonDefaultState(GOOGLE_MAP_PAGE_KEY),
  searchButtonInToolbar: false, // override
});

/**
 * Google Map initialization:
 *   - App is ready for Google Map loading when data is loaded
 *
 * Google Map restart if:
 *   - data has been updated OR at least an asset whose path contains 'files/maps/' has been updated
 *   - Google Map is not currently displayed
 *
 * @param {boolean} value
 */
const _googleMapShouldRestart = (state, action, value) =>
  updateObject(state, { shouldRestart: googleMapContext.isNotLoaded() ? false : value });

  /**
 * Google Map reset:
 *   - App is ready for Google Map loading when data is loaded
 *
 * Google Map restart if:
 *   - google maps reset state when navigate to Google Map page
 *
 * @param {boolean} value
 */
const _googleMapShouldReset = (state, action, value) =>
updateObject(state, { shouldReset: action.value});

/**
 * Initial action for this page (fetch gmapplaces data)
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
const _fetchGmapPlaces = (state, action) => {
  return updateObject(state, {
    gmapplaces: action.gmapplaces,
    gmapplacesCategories: action.gmapplacesCategories,
    data: action.data,
    isPending: action.status === STATUS.PENDING,
    shouldFetch: false,
    shouldReset: false,
  });
};

const _dataUpdated = (state, action) => {
  // Detect when app has initialized (meaning data and assets are ready for googlemap)
  if (state.isDataReady !== true) {
    return updateObject(state, { isDataReady: true });
  }
  return state;
};

export default (state = getDefaultState(), action) => {
  switch (action.type) {
    case CONFIG_JSON_LOADED:
      return configJsonLoaded(state);
    case HAS_NAVIGATED:
      return togglePageAfterNavigation(state, GOOGLE_MAP_PAGE_KEY);
    case POLL_CONFIG_LOADED:
      return pollConfigLoaded(state, action);
    case DATA_ASSETS_UPDATED:
      return _dataUpdated(state, action);
    case LANG_CHANGED:
      return langChanged(state, action);
    case FETCH_GMAP_PLACES:
      return _fetchGmapPlaces(state, action);
    case GOOGLE_MAP_RESET:
      return _googleMapShouldReset(state, action, true);
    case GOOGLE_MAP_IS_RESTARTING:
      return _googleMapShouldRestart(state, action, false);
    case GOOGLE_MAP_LOADED:
      return _googleMapShouldRestart(state, action, false);
    case GOOGLE_MAP_RELOAD:
      return _googleMapShouldRestart(state, action, true);
    case PROFILE_CHANGED:
      return profileChanged(state, action, GOOGLE_MAP_PAGE_KEY);
    /* case TOGGLE_LOCATION_STATUS:
      return toggleLocationStatus(state, action);
    case TOGGLE_LOCATION_CAPABILITY_STATUS:
      return toggleLocationCapabilityStatus(state, action);
    case TOGGLE_PMR_STATUS:
      return togglePMRStatus(state, action); */
    case TOGGLE_MENU:
      return toggleMenu(state, action, GOOGLE_MAP_PAGE_KEY); // TODO: menuOptions.disableSwipeToOpen: true ?
    case UPDATE_PAGE_STATE:
      return updatePageState(state, action, GOOGLE_MAP_PAGE_KEY);
    case WINDOW_RESIZED:
      return windowResized(state, action);

    default:
      return state;
  }
};
