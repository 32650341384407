import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { NOTES_PAGE_KEY } from 'src/pages/pagesKeys';

import CTAButton from 'src/components/cta-button/CTAButton';
import ListsPerDataType from 'src/components/list/ListsPerDataType';

class NotesContent extends Component {
  render() {
    return (
      <div className="favorites-content content-font content-below-apptoolbar">
        <div>
          <div className="notes-options-container">
            <div className="notes-button-container">
              <CTAButton
                action={() => {
                  this.props.actions.exportNotes(this.props.notes, this.props.data);
                }}
                label={this.props.labels.notes.exportButton}
              />
            </div> 
          </div>
          <div>
            <ListsPerDataType
              items={this.props.data}
              searched={this.props.searched}
              isPending={this.props.isPending}
              notes={this.props.notes}
              notesStr={JSON.stringify(this.props.notes)}
              favorites={this.props.favorites}
              favIconDisabled={this.props.favIconDisabled}
              totalCount={this.props.totalCount}
              userData={this.props.userData}
              actions={this.props.actions}
              labels={this.props.labels}
              pageKey={NOTES_PAGE_KEY}
              isNotesList
              displayFavorites={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

NotesContent.propTypes = {
  data: PropTypes.object,
  notes: PropTypes.object,
  isPending: PropTypes.bool,
  // Common
  labels: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

export default NotesContent;
