import React from 'react';
import PropTypes from 'prop-types';

import { formatDayLong } from 'src/core/Lang';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { removeHtml } from 'src/core/util/DomTools';
import { DATA_TYPE_HAPPENINGS, FORCE_ONLINE_ASSETS } from 'data/config/dataConfig';

import { HIDE_CATEGORY_ITEMS_COUNTER } from 'data/config/listConfig';

import { getBindedActions } from 'src/store/bindedActions';

/**
 * Used when a value is modified by List component
 * (e.g a string being added ...<b>...</b>... as a search result)
 * @type {String}
 */
export const ORIGINAL_PROP_PREFIX = 'original';

const getFavIconCssClasses = (itemIsFavorite) => {
  const favIconClassname = ['star', 'icon-font'];
  if (itemIsFavorite) {
    favIconClassname.push('is-fav');
  }
  return favIconClassname;
};

const renderImage = (src, className, dataType) => (
  <img
    className={`list-el-image ${className || ''}`}
    alt=""
    src={src ? getUrl(src, FORCE_ONLINE_ASSETS.includes(dataType)) : null}
  />
);

/**
 * This is a class instead of a stateless component because `ref` callback
 * is needed for ListElement of type separators. @see AlphabeticalList `getScrollTopValues`
 */
class ListElement extends React.Component {
  shouldComponentUpdate(nextProps) {
    const differs = [];
    Object.keys(nextProps).forEach((propName) => {
      if (
        nextProps[propName] !== this.props[propName] &&
        JSON.stringify(nextProps[propName]) !== JSON.stringify(this.props[propName])
      ) {
        differs.push(propName);
      }
    });

    return differs.length > 0;
  }

  render() {
    const {
      id,
      text,
      originaltext,
      textMinor,
      description,
      logo_file_name,
      originalId,
      textMinorBis,
      textStyle,
      originaltextMinor,
      hasFavoriteButton,
      showGoToButton,
      goToButtonLabel,
      event,
      isFavorite,
      isFastAndUgly,
      isSeparator,
      leftImage,
      leftImageClassName,
      image,
      image2,
      image3,
      underImage,
      counter,
      isClickable = true,
      className = '',
      isNoteItem,
      note,
      dataType,
      ...attrs
    } = this.props;

    const showFavoriteIcon = !isFastAndUgly && hasFavoriteButton === true;
    const showImage = !isFastAndUgly && typeof image === 'string' && image !== '';
    const showImage2 = !isFastAndUgly && typeof image2 === 'string' && image2 !== '';
    const showImage3 = !isFastAndUgly && typeof image3 === 'string' && image3 !== '';
    const showUnderImage = !isFastAndUgly && typeof underImage === 'string' && underImage !== '';
    const showCounter = typeof counter === 'number' && !HIDE_CATEGORY_ITEMS_COUNTER;
    if (isSeparator) {
      return (
        <li className="horizontal-separator" {...attrs}>
          <span className="separator-text">{text}</span>
        </li>
      );

      // Minimal fast&ugly mode for very long lists
    }
    if (isFastAndUgly) {
      return (
        <li data-is-clickable={isClickable === true ? '' : null} {...attrs}>
          <span>{text}</span>
        </li>
      );
    }
    const noteExcerpt =
      isNoteItem && note && note.length > 150 ? `${note.substring(0, 150)}...` : note;

    if (dataType === DATA_TYPE_HAPPENINGS) {
      const bgColorButton =
        (this.props.campaigngraphiccharter && this.props.campaigngraphiccharter.bgColor) ||
        'ca005d';
      const colorTxtButton =
        (this.props.campaigngraphiccharter && this.props.campaigngraphiccharter.colorTxt) ||
        'white';
      return (
        <li
          title={removeHtml(originaltext || text)}
          {...attrs}
          className="list-happening content-font"
        >
          <img
            className="items-list-image"
            src={getUrl(logo_file_name, FORCE_ONLINE_ASSETS.includes(dataType))}
          />

          <div
            className="items-list-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />

          {/* Itinerary button */}
          {showGoToButton && !!attrs.places.length && (
            <div
              className="item-list-itinerary"
              style={{
                backgroundColor: bgColorButton,
                color: colorTxtButton,
              }}
              onClick={() => {
                const POIs = attrs.places.map((poi) => ({
                  id,
                  originalId,
                  placeId: poi['place_id'],
                  type: dataType,
                }));
                getBindedActions().showOnePoiOnMobigeoWithoutNavigation(POIs[0]); // to do
              }}
            >
              <span className="list-el-btn-text" style={{ color: colorTxtButton }}>
                {goToButtonLabel}
              </span>

              <span
                style={{ color: colorTxtButton }}
                className="list-el-btn-icon fa fa-chevron-right"
              />
            </div>
          )}
        </li>
      );
    }
    return (
      <li
        title={removeHtml(originaltext || text)}
        className={
          className +
          (!showFavoriteIcon && (!showImage || isNoteItem) && (!event || isNoteItem)
            ? ' no-previous-sibling'
            : '')
        }
        data-is-clickable={isClickable === true ? '' : null}
        {...attrs}
      >
        {showFavoriteIcon && <span className={getFavIconCssClasses(isFavorite).join(' ')}>e</span>}

        {/* Event time */}
        {event && !isNoteItem && (
          <span className="list-el-event">
            <div
              className="start-date"
              dangerouslySetInnerHTML={{ __html: formatDayLong(event.start_date) }}
            />
            <div className="start-hour">{event.start_time}</div>
            {event.start_date !== event.end_date && (
              <div
                className="end-date"
                dangerouslySetInnerHTML={{ __html: formatDayLong(event.end_date) }}
              />
            )}
            <div className="end-hour">{event.end_time}</div>
          </span>
        )}

        {leftImage && renderImage(leftImage, leftImageClassName, dataType)}
        {!leftImage && leftImageClassName && <span className={leftImageClassName} />}

        <div className="itemWrapper">
          <span className="list-el-text">
            <span style={textStyle} dangerouslySetInnerHTML={{ __html: text }} />
            <div className="list-el-details">
              {textMinor && !isNoteItem && (
                <span className="text-minor" dangerouslySetInnerHTML={{ __html: textMinor }} />
              )}
              {textMinorBis && !isNoteItem && (
                <span
                  className="text-minor-bis"
                  dangerouslySetInnerHTML={{ __html: textMinorBis }}
                />
              )}
            </div>
          </span>

          {isNoteItem && <span className="noteExcerpt">{`"${noteExcerpt}"`}</span>}
        </div>

        <div className="list-el-icons">
          {showImage2 && (
            <img
              className="list-el-image-small"
              alt=""
              src={getUrl(image2, FORCE_ONLINE_ASSETS.includes(dataType))}
            />
          )}

          {showImage3 && (
            <img
              className="list-el-image-small"
              alt=""
              src={getUrl(image3, FORCE_ONLINE_ASSETS.includes(dataType))}
            />
          )}
        </div>

        {showImage && showUnderImage ? (
          <div className="list-el-dual-images">
            <img
              className="list-el-image"
              alt=""
              src={getUrl(image, FORCE_ONLINE_ASSETS.includes(dataType))}
            />
            <img
              className="list-el-image under-image"
              alt=""
              src={getUrl(underImage, FORCE_ONLINE_ASSETS.includes(dataType))}
            />
          </div>
        ) : (
          showImage && renderImage(image, null, dataType)
        )}

        {showCounter && (
          <span className="list-el-counter">
            <span>{counter}</span>
          </span>
        )}

        {/* Itinerary button */}
        {showGoToButton === true && <span className="list-el-btn go-to">{goToButtonLabel}</span>}

        {(isClickable && <span className="list-el-chevron fa fa-chevron-right" />) || (
          <span className="list-el-chevron fa fa-chevron-right nochevron" />
        )}
      </li>
    );
  }
}

/**
 * @see app-react/data/config/listConfig.getHelpers
 */
ListElement.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string.isRequired,
  originaltext: PropTypes.string,
  textMinor: PropTypes.string,
  logo_file_name: PropTypes.string,
  description: PropTypes.string,
  originalId: PropTypes.string,
  originaltextMinor: PropTypes.string,
  className: PropTypes.string,
  hasFavoriteButton: PropTypes.bool,
  isFavorite: PropTypes.bool,
  isSeparator: PropTypes.bool,
  showGoToButton: PropTypes.bool,
  goToButtonLabel: PropTypes.string,
  dataType: PropTypes.string,
};

export default ListElement;
